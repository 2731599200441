<template>
    <div>
        <SettingsList ref="child" :title="$t('insurance_companies.plural')" :headers="headers" :items="items"
                      :loading="loading" :url="url" :add_button_title="$t('insurance_companies.create')"
                      :delete_text="$t('insurance_companies.delete')"
                      :delete_confirmation_text="$t('insurance_companies.delete_confirmation')"
                      @show-dialog="showDialog" @view-dialog="showDialog" @update-dialog="showDialog"
                      @get-data="getData">
        </SettingsList>

        <!--Tag Dialog-->
        <Dialog :show="show_item_dialog" max_width="500px" :header="item_dialog_header"
                :is_loading="item_dialog_loading" :action="item_action" @close-dialog="closeItemDialog"
                @submit-dialog="submitItemDialog">
            <template slot="content">
                <v-form autocomplete="off" class="white_bg_form">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field name="name_en" type="text" class="custom_field" v-model="item.name_en"
                                          :label="$t('general.name')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.name_en.$touch()" @blur="$v.item.name_en.$touch()"
                                          :error-messages="fieldErrors('name_en')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="vat" type="text" class="custom_field" v-model="item.vat"
                                          :label="$t('general.vat')" outlined clearable :disabled="isDisabled">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="address" type="text" class="custom_field" v-model="item.address"
                                          :label="$t('general.address')" outlined clearable :disabled="isDisabled">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import SettingsList from '../../components/SettingsList.vue'
    import Dialog from '../../components/Dialog.vue'
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'

    export default {
        components: {
            SettingsList, Dialog
        },
        mixins: [validationMixin],
        validations: {
            item: {
                name_en: {
                    required
                }
            }
        },
        data: self => ({
            // datatable
            headers: [],
            items: [],
            loading: false,
            url: 'insurance-companies',

            // dialog
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item: {
                name_el: null,
                name_en: null,
                vat: null,
                address: null
            },
            item_id: null,
            item_action: null
        }),
        computed: {
            isDisabled() {
                return this.item_action === 'view';
            },
            locale() {
                return this.$i18n.locale;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        mounted() {
            this.getData();
        },
        watch: {
            locale(val) {
                this.headers = this.getHeaders();
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.name')}`, value: 'name_en', sortable: true},
                    {text: `${this.$t('general.vat')}`, value: 'vat', sortable: true},
                    {text: `${this.$t('general.address')}`, value: 'address', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ];
            },

            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;
                !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // get data from API
            async getData() {
                this.loading = true;
                let response = await this.$store.dispatch('apiRequest', {method: 'get', url: this.url});

                // successful response
                if (!response.response) {
                    this.items = response.data;
                }

                this.loading = false;
            },
            // show item dialog
            showDialog(item, action) {
                this.show_item_dialog = true;
                this.item_action = action;
                this.item_dialog_header = action === 'create' ? this.$t('insurance_companies.create') :
                    action === 'view' ? this.$t('insurance_companies.view') : this.$t('insurance_companies.update');

                if (item) {
                    this.setItemInfo(item);
                }
            },
            // set item info for update and view
            setItemInfo(item) {
                this.item_id = item.id;

                for (let prop in this.item) {
                    this.item[prop] = item[prop];
                }
            },
            // close item dialog
            closeItemDialog() {
                this.$v.item.$reset();
                this.show_item_dialog = false;
                this.item_id = null;
                this.item_action = null;

                for (let prop in this.item) {
                    this.item[prop] = null;
                }
            },
            // submit item dialog
            async submitItemDialog() {
                this.$v.item.$touch();

                // login data are valid
                if (!this.$v.item.$invalid) {
                    this.item_dialog_loading = true;
                    let item = Object.assign({}, this.item);

                    let response = await this.$refs.child.submitItem(item, this.item_id);

                    // stop dialog submit button loading
                    this.item_dialog_loading = false;

                    // successful response
                    if (!response.response) {

                        // close item dialog
                        this.closeItemDialog();

                        // get items
                        this.getData();
                    }
                }
            }
        }
    }
</script>